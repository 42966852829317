import React, { useMemo, useState, useRef } from 'react';
import {
  Alert,
  Box,
  Button,
  Grid,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { createValidationSchema } from './validationSchema';
import { sendMessage } from '../services/emailService';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import ReCAPTCHA from 'react-google-recaptcha';
import Wave from '../wave/Wave';

interface FormData {
  name: string;
  subject: string;
  email: string;
  comment: string;
}

const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;

const ContactForm: React.FC = () => {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>(
    'success'
  );
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const recaptchaRef = useRef<ReCAPTCHA>(null);
  const validationSchema = useMemo(() => createValidationSchema(t), [t]);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: '',
      email: '',
      subject: '',
      comment: '',
    },
  });

  const onSubmit = async (data: FormData) => {
    const recaptchaValue = recaptchaRef.current?.getValue();

    if (!recaptchaValue) {
      setSnackbarSeverity('error');
      setSnackbarMessage(`${t('contactForm:snackbarMessageRobotCheck')}`);
      setOpenSnackbar(true);
      return;
    }

    const templateId = process.env.REACT_APP_TEMPLATE_ID!;
    const serviceID = process.env.REACT_APP_SERVICE_ID!;

    try {
      await sendMessage(serviceID, templateId, {
        from_name: data.name,
        message_html: data.comment,
        reply_to: data.email,
        subject: data.subject,
        'g-recaptcha-response': recaptchaValue,
      });

      setSnackbarSeverity('success');
      setSnackbarMessage(`${t('contactForm:snackbarMessageSuccess')}`);
      setOpenSnackbar(true);

      reset();
      recaptchaRef.current?.reset();
    } catch (error) {
      setSnackbarSeverity('error');
      setSnackbarMessage(`${t('contactForm:snackbarMessageError')}`);
      setOpenSnackbar(true);

      console.error('Error sending email:', error);
    }
  };

  return (
    <Box sx={{ padding: '2rem 0 3rem' }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            backgroundColor: '#ffffff',
            p: 3,
            borderRadius: '.5rem',
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              typography: { md: 'h2', xs: 'h4' },
              fontWeight: { md: 600, xs: 700 },
            }}
          >
            {t('contactForm:getInTouch')}
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              padding: { md: '2rem 2rem 0 2rem', xs: '.1rem' },
              margin: '0 auto',
            }}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Box
                  p={1}
                  sx={{ display: 'flex', alignItems: 'flex-end' }}
                >
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label={t('contactForm:fullName')}
                        variant="standard"
                        {...field}
                        error={!!errors.name}
                        helperText={errors.name?.message}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
              >
                <Box p={1}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label={t('contactForm:email')}
                        variant="standard"
                        {...field}
                        error={!!errors.email}
                        helperText={errors.email?.message}
                        inputProps={{
                          maxLength: 50,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Box p={1}>
                  <Controller
                    name="subject"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        label={t('contactForm:subject')}
                        variant="standard"
                        {...field}
                        error={!!errors.subject}
                        helperText={errors.subject?.message}
                        inputProps={{
                          maxLength: 80,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Box p={1}>
                  <Controller
                    name="comment"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        multiline
                        minRows={10}
                        maxRows={10}
                        label={t('contactForm:comment')}
                        variant="standard"
                        {...field}
                        error={!!errors.comment}
                        helperText={errors.comment?.message}
                        inputProps={{
                          maxLength: 1500,
                        }}
                      />
                    )}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Box mt={3}>
                  <ReCAPTCHA
                    key={isSmallScreen ? 'compact' : 'normal'}
                    size={isSmallScreen ? 'compact' : 'normal'}
                    ref={recaptchaRef}
                    sitekey={RECAPTCHA_SITE_KEY}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
              >
                <Box
                  display="flex"
                  justifyContent="center"
                  sx={{ zIndex: 1, position: 'relative', bottom: -36 }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    sx={{ width: { xs: '100%', md: '19rem' } }}
                    disabled={Object.keys(errors).length > 0}
                  >
                    {t('contactForm:btnSend')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ position: 'relative', zIndex: 0 }}>
            <Wave />
          </Box>
        </Box>
      </form>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={() => setOpenSnackbar(false)}
      >
        <Alert
          onClose={() => setOpenSnackbar(false)}
          severity={snackbarSeverity}
          sx={{ width: '100%' }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ContactForm;
