import * as React from 'react';
import { experimentalStyled as styled } from '@mui/material/styles';
import {
  Avatar,
  Badge,
  Box,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  Grid,
  Link,
  List,
  Typography,
} from '@mui/material';
import avatar from '../img/avatar.png';
import logo from '../img/myLogo.png';
import { default as cardsData } from '../../data/dataPortfolio.json';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';
import Wave from '../wave/Wave';

interface ICardData {
  cardContent?: ReactNode;
  id: string;
  companyName: string;
  companyLogo: string;
  projectName: string;
  technicalStack: string[];
  i18n: {
    lang: string;
    mission: string[];
  }[];
}

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 40,
  height: 40,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const extractUrlAndText = (text: string): [string, string, string] => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const matches = text.match(urlRegex);
  if (matches) {
    const url = matches[0];
    const parts = text.split(url);
    return [parts[0], url, parts[1] || ''];
  }
  return [text, '', ''];
};

const PortfolioCards = () => {
  const { t, i18n } = useTranslation();

  const cards: ICardData[] = cardsData;

  const [currentLanguage, setCurrentLanguage] = React.useState(i18n.language);

  React.useEffect(() => {
    setCurrentLanguage(i18n.language);
  }, [i18n.language]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getTranslation = (translations: any[]) => {
    const primaryLanguage = currentLanguage.split('-')[0]; // Extract primary language code
    if (primaryLanguage === 'en' || primaryLanguage === 'fr') {
      const translation = translations.find((i) => i.lang === primaryLanguage);
      if (translation) {
        return translation;
      } else {
        return translations.find((i) => i.lang === i18n.options.fallbackLng);
      }
    } else {
      return translations.find((i) => i.lang === 'fr');
    }
  };

  return (
    <Box sx={{ flexGrow: 1, padding: '2rem 0' }}>
      <Grid
        container
        spacing={4}
        columns={{ xs: 1, sm: 8, md: 12 }}
      >
        {cards.map((card) => {
          const translation = getTranslation(card.i18n);
          if (!translation) return null;
          return (
            <Grid
              item
              xs={2}
              sm={4}
              md={4}
              key={card.id}
            >
              <Card
                sx={{
                  maxWidth: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  position: 'relative',
                  transition: 'transform 0.3s ease-in-out',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  },
                }}
              >
                <CardHeader
                  avatar={
                    <Badge
                      overlap="circular"
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                      badgeContent={
                        <SmallAvatar
                          alt="Logo"
                          src={logo}
                        />
                      }
                    >
                      <Avatar
                        sx={{ width: 56, height: 56 }}
                        alt="Vladimir Ursu"
                        src={avatar}
                      />
                    </Badge>
                  }
                  title={t('portfolioCards:titlePosition')}
                  subheader={card.companyName}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CardMedia
                    sx={{ width: '100px' }}
                    component="img"
                    height="100"
                    image={require(`../img/${card.companyLogo}`)}
                    alt={card.companyName}
                  />
                </Box>
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography
                    variant="body1"
                    textAlign="center"
                  >
                    {t('portfolioCards:project')}{' '}
                    <span style={{ fontWeight: 700 }}>{card.projectName}</span>
                  </Typography>
                  <List>
                    {translation.mission.map(
                      (
                        list: string,
                        listIndex: React.Key | null | undefined
                      ) => {
                        const [textBefore, url, textAfter] =
                          extractUrlAndText(list);
                        return (
                          <ul key={listIndex}>
                            <li>
                              {textBefore}
                              {url && (
                                <Link
                                  href={url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {url}
                                </Link>
                              )}
                              {textAfter}
                            </li>
                          </ul>
                        );
                      }
                    )}
                  </List>
                </CardContent>
                <Box>
                  <Wave />
                </Box>
                <Box sx={{ backgroundColor: '#ededed', padding: 2 }}>
                  <Typography
                    textAlign="center"
                    fontStyle="italic"
                  >
                    {t('portfolioCards:technologies')}
                  </Typography>
                  <Box
                    sx={{
                      paddingTop: 1,
                      display: 'flex',
                      flexWrap: 'wrap',
                      justifyContent: 'space-around',
                    }}
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid
                        container
                        spacing={2}
                        columns={12}
                        pl={1}
                      >
                        {card.technicalStack.map(
                          (
                            stack: string,
                            stackIndex: React.Key | null | undefined
                          ) => (
                            <Grid
                              p={1}
                              item
                              xs={2}
                              sm={2}
                              key={stackIndex}
                            >
                              <Avatar
                                title={stack.slice(0, -4)}
                                alt={stack.slice(0, -4)}
                                sx={{ width: 30, height: 30 }}
                                src={require(`../img/stackLogo/${stack}`)}
                              />
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default PortfolioCards;
