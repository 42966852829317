import React from 'react';
import { styled } from '@mui/system';

const animationStyles = `
  @keyframes waveAnimation {
    0%, 100% { transform: rotate(0deg); }
    10% { transform: rotate(14deg); }
    20% { transform: rotate(-8deg); }
    30% { transform: rotate(14deg); }
    40% { transform: rotate(-4deg); }
    50% { transform: rotate(10deg); }
    60% { transform: rotate(0deg); }
  }
`;

const WaveSpan = styled('span')({
  display: 'inline-block',
  animation: 'waveAnimation 2.5s 2',
  transformOrigin: '70% 70%',
});

const WavingHandEmoji: React.FC = () => {
  return (
    <>
      <style>{animationStyles}</style>
      <WaveSpan aria-label="waving hand">👋</WaveSpan>
    </>
  );
};

export default WavingHandEmoji;
