import * as React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles'; // Добавьте эту строку
import { useTranslation } from 'react-i18next';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const CustomTabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

const SkillsTabs = () => {
  const [value, setValue] = React.useState(1);
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const { t } = useTranslation();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box sx={{ maxWidth: '100%', bgcolor: 'background.paper' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs"
          variant={isMobileScreen ? 'scrollable' : 'fullWidth'}
          centered={isMobileScreen ? false : true}
          allowScrollButtonsMobile
        >
          <Tab
            label={t('skillsTabs:programmingLang')}
            {...a11yProps(0)}
          />
          <Tab
            label={t('skillsTabs:librariesFrameworks')}
            {...a11yProps(1)}
          />
          <Tab
            label={t('skillsTabs:tools')}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <CustomTabPanel
        value={value}
        index={0}
      >
        JavaScript, Typescript, HTML5, CSS3, JSON, SASS, LESS.
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={1}
      >
        ReactJS, NextJS, React-Router, Redux, Rest API, Auth0, Firebase, i18n,
        JQuery, MUI, Tailwind, SemanticUI, Bootstrap, Storybook, React-Flow,
        AngularJS, Google Analytics, Vercel Analytics, Jest, Cypress, MongoDB,
        MySQL, SQL.
      </CustomTabPanel>
      <CustomTabPanel
        value={value}
        index={2}
      >
        Git, GitHub, GitLab, Bitbucket, JIRA, Trello, Agile, Kanban, yarn, NPM,
        Grunt, Gulp, Visual Studio, Intellij Idea, Postman, Notepad ++,
        Sourcetree, Corel Draw, Adobe Photoshop, Adobe Illustrator, Axure,
        Figma, WordPress, MacOS, Windows, Linux.
      </CustomTabPanel>
    </Box>
  );
};

export default SkillsTabs;
