import blockquote from './fr/blockquote.json';
import contactForm from './fr/contactForm.json';
import contentAbout from './fr/contentAbout.json';
import contentPortfolio from './fr/contentPortfolio.json';
import cookieBanner from './fr/cookieBanner.json';
import cookiesPolicy from './fr/cookiesPolicy.json';
import homePage from './fr/homePage.json';
import languageSwitch from './fr/languageSwitch.json';
import navBar from './fr/navBar.json';
import notFoundPage from './fr/notFoundPage.json';
import portfolioCards from './fr/portfolioCards.json';
import skillsTabs from './fr/skillsTabs.json';
import scrollToTop from './fr/scrollToTop.json';

export const resourcesFr = {
  blockquote,
  contactForm,
  contentAbout,
  contentPortfolio,
  cookieBanner,
  cookiesPolicy,
  homePage,
  languageSwitch,
  navBar,
  notFoundPage,
  portfolioCards,
  skillsTabs,
  scrollToTop
};
