import * as yup from 'yup';

type TFunction = (key: string) => string;

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const createValidationSchema = (t: TFunction) =>
  yup.object().shape({
    name: yup
      .string()
      .required(t('contactForm:errorFullName'))
      .matches(/^\S/, t('contactForm:errorNameStartWithSpace'))
      .matches(/^[^\d]+$/, t('contactForm:errorNameNoNumbers'))
      .matches(/.*\S.*/, t('contactForm:errorNameNotOnlySpaces'))
      .max(49, t('contactForm:errorNameMaxLength')),
    email: yup
      .string()
      .required(t('contactForm:errorEmail'))
      .matches(emailRegex, t('contactForm:errorEmailInvalid')),
    subject: yup
      .string()
      .required(t('contactForm:errorSubject'))
      .matches(/^\S/, t('contactForm:errorSubjectStartWithSpace'))
      .matches(/.*\S.*/, t('contactForm:errorSubjectNotOnlySpaces'))
      .max(79, t('contactForm:errorSubjectMaxLength')),
    comment: yup
      .string()
      .required(t('contactForm:errorComment'))
      .matches(/^\S/, t('contactForm:errorCommentStartWithSpace'))
      .matches(/.*\S.*/, t('contactForm:errorCommentNotOnlySpaces'))
      .max(1499, t('contactForm:errorCommentMaxLength')),
  });
