import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Link,
} from '@mui/material';
import { useTranslation } from 'react-i18next';

const ActionDialog = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
    >
      <DialogTitle>{t('cookiesPolicy:title')}</DialogTitle>
      <DialogContent>
        <Typography sx={{ fontWeight: 700 }}>
          {t('cookiesPolicy:descriptionCookiesTitle')}
        </Typography>
        <Typography>{t('cookiesPolicy:descriptionCookiesPart1')}</Typography>
        <br />
        <Typography>{t('cookiesPolicy:descriptionCookiesPart2')}</Typography>
        <br />
        <Typography>
          {t('cookiesPolicy:descriptionCookiesPart3')}{' '}
          <Link
            href={t('cookiesPolicy:descriptionCookiesLink')}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('cookiesPolicy:descriptionCookiesLink')}
          </Link>
        </Typography>
        <br />
        <Typography sx={{ fontWeight: 700 }}>
          {t('cookiesPolicy:howToWeUseCookiesTitle')}
        </Typography>
        <Typography>{t('cookiesPolicy:howToWeUseCookiesPart1')}</Typography>
        <br />
        <Typography>{t('cookiesPolicy:howToWeUseCookiesPart2')}</Typography>
        <br />
        <Typography sx={{ fontWeight: 700 }}>
          {t('cookiesPolicy:disablingCookiesTitle')}
        </Typography>
        <Typography>{t('cookiesPolicy:disablingCookiesPart1')}</Typography>
        <br />

        <Typography>{t('cookiesPolicy:disablingCookiesPart2')}</Typography>
        <br />
        <Typography sx={{ fontWeight: 700 }}>
          {t('cookiesPolicy:cookiesGoogleAnalyticsTitle')}
        </Typography>
        <Typography>
          {t('cookiesPolicy:cookiesGoogleAnalyticsPart1')}
        </Typography>
        <br />
        <Typography>
          {t('cookiesPolicy:cookiesGoogleAnalyticsPart2')}
        </Typography>
        <br />
        <Typography>
          {t('cookiesPolicy:cookiesGoogleAnalyticsPart3')}
        </Typography>
        <br />
        <Typography sx={{ fontWeight: 700 }}>
          {t('cookiesPolicy:cookiesMoreInfoTitle')}
        </Typography>
        <Typography>{t('cookiesPolicy:cookiesMoreInfoPart1')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          {t('cookiesPolicy:actionCloseBtn')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
