import React, { useState, useEffect, FC } from 'react';
import { Snackbar, Alert, Typography, Button, Link } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ActionDialog from './ActionDialog';

const Banner: FC = () => {
  const { t } = useTranslation();
  const [openSnackbar, setOpenSnackbar] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const shouldHideDialog = localStorage.getItem('hideDialog');
    if (shouldHideDialog === 'true') {
      setOpenSnackbar(false);
    }
  }, []);

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
    localStorage.setItem('hideDialog', 'true');
  };

  const handleDialogOpen = () => {
    setOpenDialog(true);
    setOpenSnackbar(false);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    setOpenSnackbar(true);
  };

  return (
    <>
      <Snackbar
        open={openSnackbar}
        sx={{ maxWidth: 500 }}
      >
        <Alert severity="info">
          <Typography variant="body2">
            {t('cookieBanner:title')}{' '}
            <Link
              onClick={handleDialogOpen}
              sx={{ cursor: 'pointer' }}
            >
              {t('cookieBanner:actonLink')}
            </Link>
          </Typography>
          <Button
            size="small"
            sx={{ mt: 1 }}
            variant="contained"
            onClick={handleSnackbarClose}
          >
            {t('cookieBanner:actonBtn')}
          </Button>
        </Alert>
      </Snackbar>
      <ActionDialog
        open={openDialog}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default Banner;
