import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';

const ScrollToTopButton: React.FC = () => {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Tooltip title={t('scrollToTop:tooltipTitle')}>
      <Button
        color="primary"
        aria-label="scroll back to top"
        onClick={scrollToTop}
      >
        <ArrowCircleUpIcon />
      </Button>
    </Tooltip>
  );
};

export default ScrollToTopButton;
