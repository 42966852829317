import blockquote from './en/blockquote.json';
import contactForm from './en/contactForm.json';
import contentAbout from './en/contentAbout.json';
import contentPortfolio from './en/contentPortfolio.json';
import cookieBanner from './en/cookieBanner.json';
import cookiesPolicy from './en/cookiesPolicy.json';
import homePage from './en/homePage.json';
import languageSwitch from './en/languageSwitch.json';
import navBar from './en/navBar.json';
import notFoundPage from './en/notFoundPage.json';
import portfolioCards from './en/portfolioCards.json';
import skillsTabs from './en/skillsTabs.json';
import scrollToTop from './en/scrollToTop.json';

export const resourcesEn = {
  blockquote,
  contactForm,
  contentAbout,
  contentPortfolio,
  cookieBanner,
  cookiesPolicy,
  homePage,
  languageSwitch,
  navBar,
  notFoundPage,
  portfolioCards,
  skillsTabs,
  scrollToTop
};
