import React from 'react';
import WavingHandEmoji from '../../animation/wavingHandEmoji';
import { Link, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Banner from '../../cookieBanner/Banner';

const ContentAbout = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography
        fontWeight={400}
        fontSize={{ xs: 21, sm: 26 }}
        color="#ffffff"
        sx={{ textAlign: { md: 'left', xs: 'center' } }}
      >
        {t('contentAbout:greetings')} <WavingHandEmoji />,
      </Typography>
      <Typography
        fontWeight={200}
        fontSize={{ xs: 21, sm: 26 }}
        color="#ffffff"
        pt={1}
      >
        {t('contentAbout:textPart1')}
      </Typography>
      <Typography
        fontWeight={200}
        fontSize={{ xs: 21, sm: 26 }}
        color="#ffffff"
        pt={3}
      >
        {t('contentAbout:textPart2')}
      </Typography>
      <Typography
        fontWeight={200}
        fontSize={{ xs: 21, sm: 26 }}
        color="#ffffff"
        pt={3}
      >
        🚀 {t('contentAbout:textPart3')}
      </Typography>
      <Typography
        fontWeight={200}
        fontSize={{ xs: 21, sm: 26 }}
        color="#ffffff"
        pt={3}
      >
        {t('contentAbout:textPart4')}{' '}
        <span>
          <Link
            href="mailto:vlad.geo.ursu@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            vlad.geo.ursu@gmail.com
          </Link>
          .
        </span>
      </Typography>
      <Banner />
    </>
  );
};

export default ContentAbout;
