import React from 'react';
import HomePage from './components/pages/homePage';
import './i18n';
import './firebase';

const App = () => {
  return <HomePage />;
};

export default App;
