import React from 'react';
import { Avatar, Box, Grid, Link, Typography } from '@mui/material';
import PortfolioCards from '../../portfolioCards';
import SkillsTabs from '../../skillsTabs';
import workImg from '../../img/work.png';
import BlockquoteComponent from '../../blockquoteComponent';
import { useTranslation } from 'react-i18next';
import Banner from '../../cookieBanner/Banner';

const ContentPortfolio = () => {
  const { t } = useTranslation();

  return (
    <>
      <Box pb={3}>
        <Grid
          container
          rowSpacing={1}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            sx={{
              display: 'flex',
              justifyContent: { xs: 'center', sm: 'center', md: 'left' },
            }}
          >
            <Avatar
              alt="web developer work"
              src={workImg}
              variant="rounded"
              sx={{
                width: { xs: '200px', sm: '250px' },
                height: { xs: '200px', sm: '250px' },
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <BlockquoteComponent />
            <Typography
              sx={{
                paddingTop: 2,
                textAlign: { xs: 'center', sm: 'center', md: 'left' },
              }}
            >
              {t('contentPortfolio:textPart1')}{' '}
              <span>
                <Link
                  href="https://www.linkedin.com/in/vladimir-ursu"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </Link>
              </span>{' '}
              {t('contentPortfolio:textPart2')}
              <br />
              🚀 {t('contentPortfolio:textPart3')}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <SkillsTabs />
      <PortfolioCards />
      <Banner />
    </>
  );
};

export default ContentPortfolio;
