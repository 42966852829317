import React, { FC, useState } from 'react';
import {
  Avatar,
  Box,
  Container,
  Grid,
  IconButton,
  Link,
  styled,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import avatar from '../../img/avatar.png';
import Banner from '../../cookieBanner';
import ContactForm from '../../contactForm/ContactForm';
import ContentAbout from './ContentAbout';
import ContentPortfolio from './ContentPortfolio';
import logo from '../../img/myLogo.png';
import NavButtonGroup from '../../navButtonGroup/NavButtonGroup';

// Create styles for the component
const AnimatedLeftBlock = styled(motion.div)({
  backgroundColor: '#005682',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
});

const AnimatedRightBlock = styled(motion.div)({
  backgroundColor: '#ffffff',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
});

const animationVariants = {
  hidden: { width: 0 },
  visible: { width: '100%' },
};

const FullWidthContainer = styled('div')(() => ({
  width: '100%',
}));

const HomePage: FC = () => {
  const [showRightBlock, setShowRightBlock] = useState(true);
  const [showLeftBlock, setShowLeftBlock] = useState(true);
  const [showOpenButton, setShowOpenButton] = useState(true);
  const theme = useTheme();
  const isMdOrLarger = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();

  const handleOpenLeftBlock = () => {
    setShowRightBlock(false);
    setShowOpenButton(false);
  };

  const handleOpenRightBlock = () => {
    setShowLeftBlock(false);
    setShowOpenButton(false);
  };

  const handleBack = () => {
    setShowRightBlock(true);
    setShowLeftBlock(true);
    setShowOpenButton(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        spacing={0}
        sx={{ height: '100vh' }}
      >
        {/* Left Block */}
        <Grid
          item
          xs={showRightBlock ? 6 : 12}
        >
          <AnimatedLeftBlock
            initial="hidden"
            animate={showLeftBlock ? 'visible' : 'hidden'}
            variants={animationVariants}
            transition={{ duration: 0.5 }}
          >
            {showLeftBlock ? (
              <>
                {showOpenButton ? (
                  <>
                    <IconButton
                      onClick={handleOpenLeftBlock}
                      sx={{
                        width: { xs: '100px', sm: '200px', lg: '300px' },
                        height: { xs: '100px', sm: '200px', lg: '300px' },
                        boxShadow: '5px 5px 15px 1px #000000',
                      }}
                    >
                      <Avatar
                        alt="avatar img"
                        src={avatar}
                        sx={{
                          width: { xs: '100px', sm: '200px', lg: '300px' },
                          height: { xs: '100px', sm: '200px', lg: '300px' },
                        }}
                      />
                    </IconButton>
                    <Typography
                      onClick={handleOpenLeftBlock}
                      component={Link}
                      textAlign={'center'}
                      mt={3}
                      color="#ffffff"
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        borderBottom: '2px solid #ffffff',
                        pb: 1,
                        typography: { md: 'h3', sm: 'h4', xs: 'h5' },
                      }}
                    >
                      Vladimir Ursu
                    </Typography>
                    <Typography
                      onClick={handleOpenLeftBlock}
                      component={Link}
                      textAlign={'center'}
                      mt={1}
                      color="#ffffff"
                      sx={{
                        cursor: 'pointer',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        typography: { md: 'h5', sm: 'h6', xs: 'subtitle2' },
                      }}
                    >
                      {t('homePage:title')}
                    </Typography>
                    <Banner />
                  </>
                ) : (
                  <FullWidthContainer sx={{ backgroundColor: '#34495e' }}>
                    <Container
                      sx={{ padding: isMdOrLarger ? '0 0 2rem 0' : '1rem 0' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingBottom: '2rem',
                          position: isMdOrLarger ? 'fixed' : 'static',
                          bottom: isMdOrLarger ? 0 : 'auto',
                          right: 0,
                          zIndex: '2',
                          width: '100%',
                          paddingTop: isMdOrLarger ? 0 : 1,
                          marginBottom: isMdOrLarger ? 2 : 0,
                        }}
                      >
                        <NavButtonGroup handleBack={handleBack} />
                      </Box>
                      <Container
                        maxWidth="lg"
                        sx={{ backgroundColor: '#34495e', marginTop: '2rem' }}
                      >
                        <ContentAbout />
                        <ContactForm />
                      </Container>
                    </Container>
                  </FullWidthContainer>
                )}
              </>
            ) : null}
          </AnimatedLeftBlock>
        </Grid>
        {/* Right Block */}
        <Grid
          item
          xs={showLeftBlock ? 6 : 12}
        >
          <AnimatedRightBlock
            initial="hidden"
            animate={showRightBlock ? 'visible' : 'hidden'}
            variants={animationVariants}
            transition={{ duration: 0.5 }}
          >
            {showRightBlock ? (
              <>
                {showOpenButton ? (
                  <>
                    <IconButton
                      onClick={handleOpenRightBlock}
                      sx={{
                        width: { xs: '100px', sm: '200px', lg: '300px' },
                        height: { xs: '100px', sm: '200px', lg: '300px' },
                        boxShadow: '5px 5px 15px 1px #000000',
                      }}
                    >
                      <Avatar
                        alt="logo round"
                        src={logo}
                        sx={{
                          width: { xs: '100px', sm: '200px', lg: '300px' },
                          height: { xs: '100px', sm: '200px', lg: '300px' },
                        }}
                      />
                    </IconButton>
                    <Typography
                      onClick={handleOpenRightBlock}
                      component={Link}
                      textAlign={'center'}
                      mt={3}
                      sx={{
                        color: 'inherit',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        borderBottom: '2px solid lightgray',
                        pb: 1,
                        typography: { md: 'h3', sm: 'h4', xs: 'h5' },
                      }}
                    >
                      {t('homePage:viewMy')}
                    </Typography>
                    <Typography
                      onClick={handleOpenRightBlock}
                      component={Link}
                      textAlign={'center'}
                      mt={1}
                      sx={{
                        color: 'inherit',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        textTransform: 'uppercase',
                        typography: { md: 'h5', sm: 'h6', xs: 'subtitle2' },
                      }}
                    >
                      Portfolio
                    </Typography>
                  </>
                ) : (
                  <FullWidthContainer sx={{ backgroundColor: '#ecf0f1' }}>
                    <Container
                      sx={{ padding: isMdOrLarger ? '0 0 2rem 0' : '1rem 0' }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          paddingBottom: '2rem',
                          position: isMdOrLarger ? 'fixed' : 'static',
                          bottom: isMdOrLarger ? 0 : 'auto',
                          right: 0,
                          zIndex: '2',
                          width: '100%',
                          paddingTop: isMdOrLarger ? 0 : 1,
                          marginBottom: isMdOrLarger ? 2 : 0,
                        }}
                      >
                        <NavButtonGroup handleBack={handleBack} />
                      </Box>
                      <Container
                        maxWidth="lg"
                        sx={{ backgroundColor: '#ecf0f1', marginTop: '2rem' }}
                      >
                        <ContentPortfolio />
                      </Container>
                    </Container>
                  </FullWidthContainer>
                )}
              </>
            ) : null}
          </AnimatedRightBlock>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default HomePage;
