// import React from 'react';
// import { keyframes } from '@emotion/react';
// import styled from '@emotion/styled';

// // Анимация для движения волн
// const waveAnimation = keyframes`
//   0% { transform: translateX(0); }
//   100% { transform: translateX(-50%); }
// `;

// // Стили для контейнера с волнами
// const WaveContainer = styled.div`
//   position: relative;
//   width: 100%;
//   height: 100px;
//   overflow: hidden;
// `;

// const WaveSvg = styled.svg`
//   position: absolute;
//   bottom: 0;
//   left: 0;
//   width: 200%;
//   height: 100%;
//   animation: ${waveAnimation} 10s linear infinite;
// `;

// const Wave: React.FC = () => {
//   return (
//     <WaveContainer>
//       <WaveSvg viewBox="0 0 500 150" preserveAspectRatio="none">
//         <path
//           d="M0.00,49.98 C150.00,150.00 349.80,-49.98 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
//           style={{ fill: 'rgba(0, 188, 212, 0.5)' }}
//         />
//         <path
//           d="M0.00,99.98 C200.00,200.00 299.80,-49.98 500.00,99.98 L500.00,150.00 L0.00,150.00 Z"
//           style={{ fill: 'rgba(0, 188, 212, 0.7)' }}
//         />
//       </WaveSvg>
//     </WaveContainer>
//   );
// };

// export default Wave;

import React from 'react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

// Анимация для первой волны
const waveAnimation1 = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

// Анимация для второй волны (быстрее)
const waveAnimation2 = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

// Стили для контейнера с волнами
const WaveContainer = styled.div`
  position: relative;
  width: 100%;
  height: 130px; // Увеличиваем высоту для трех гребешков
  overflow: hidden;
`;

const WaveSvg = styled.svg`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 200%;
  height: 60%;
  animation-timing-function: linear;
`;

const WaveSvg1 = styled(WaveSvg)`
  animation: ${waveAnimation1} 16s linear infinite;
`;

const WaveSvg2 = styled(WaveSvg)`
  animation: ${waveAnimation2} 8s linear infinite;
`;

const Wave: React.FC = () => {
  return (
    <WaveContainer>
      <WaveSvg1
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path
          d="M0,160 C120,260 240,60 360,160 C480,260 600,60 720,160 C840,260 960,60 1080,160 C1200,260 1320,60 1440,160 L1440,320 L0,320 Z"
          style={{ fill: 'rgba(25, 118, 210, 0.4)' }}
        />
      </WaveSvg1>
      <WaveSvg2
        viewBox="0 0 1440 320"
        preserveAspectRatio="none"
      >
        <path
          d="M0,160 C120,240 240,80 360,160 C480,240 600,80 720,160 C840,240 960,80 1080,160 C1200,240 1320,80 1440,160 L1440,320 L0,320 Z"
          style={{ fill: 'rgba(25, 118, 210, 0.6)' }}
        />
      </WaveSvg2>
    </WaveContainer>
  );
};

export default Wave;
