import emailjs from 'emailjs-com';

interface EmailVariables {
  [key: string]: string;
}

// Initialize emailjs with your USER_ID
const userId = process.env.REACT_APP_USER_ID!;
emailjs.init(userId);

export const sendMessage = async (
  serviceID: string,
  templateId: string,
  variables: EmailVariables
): Promise<void> => {
  await emailjs.send(serviceID, templateId, variables);
};
