import React, { FC } from 'react';
import { Button, ButtonGroup } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from '../languageSwitch';
import ReplyIcon from '@mui/icons-material/Reply';
import ScrollToTopButton from '../scrollToTopButton';

interface INavButtonGroupProps {
  handleBack: () => void;
}

const NavButtonGroup: FC<INavButtonGroupProps> = ({ handleBack }) => {
  const { t } = useTranslation();
  return (
    <ButtonGroup
      size="small"
      variant="contained"
      aria-label="Basic button group"
      sx={{ position: 'fixed', zIndex: '10' }}
    >
      <LanguageSwitch />
      <Button
        sx={{ paddingTop: '0.3rem' }}
        variant="contained"
        onClick={handleBack}
        startIcon={<ReplyIcon />}
      >
        {t('homePage:btnToBack')}
      </Button>
      <ScrollToTopButton />
    </ButtonGroup>
  );
};

export default NavButtonGroup;
