import React, { useState, useEffect } from 'react';
import { makeStyles } from '@mui/styles';
import { Paper, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const useStyles = makeStyles(() => ({
  blockquote: {
    background: '#F0F0F1',
    color: '#333334',
    padding: '1.25rem 1.875rem',
    position: 'relative',
    borderLeft: '2.1875rem solid #DFDEDE',
    fontFamily: 'Lato, sans-serif',
  },
  quoteIcon: {
    fontSize: '3.125rem',
    position: 'absolute',
    left: '-1.8125rem',
    top: '0.3125rem',
    color: '#BCBCBC',
    textShadow: '0.0625rem 0.125rem 0 white',
  },
  text: {
    margin: '0 0 1rem',
    fontSize: '1.25rem',
    letterSpacing: '0.05em',
    lineHeight: 1.4,
  },
  citation: {
    fontStyle: 'normal',
    fontWeight: 300,
  },
}));

interface TypingTextProps {
  text: string;
  speed?: number;
  onComplete?: () => void;
}

const TypingText: React.FC<TypingTextProps> = ({
  text,
  speed = 100,
  onComplete,
}) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    let index = 0;
    const intervalId = setInterval(() => {
      setDisplayedText(text.slice(0, index + 1));
      index += 1;
      if (index === text.length) {
        clearInterval(intervalId);
        if (onComplete) {
          onComplete();
        }
      }
    }, speed);

    return () => clearInterval(intervalId);
  }, [text, speed, onComplete]);

  return <Typography component="div">{displayedText}</Typography>;
};

const BlockquoteComponent: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Paper
      elevation={3}
      className={classes.blockquote}
    >
      <FormatQuoteIcon className={classes.quoteIcon} />
      <Box>
        <Typography
          variant="body1"
          className={classes.text}
        >
          {t('blockquote:citation')}
        </Typography>
        <TypingText
          text={t('blockquote:author')}
          speed={150}
        />
      </Box>
    </Paper>
  );
};

export default BlockquoteComponent;
