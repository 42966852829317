import React from 'react';
import { Button, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';

const LanguageSwitch = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language === 'fr' ? 'en' : 'fr';

  const handleLanguageSwitch = useCallback(() => {
    i18n.changeLanguage(lang);
  }, [i18n, lang]);

  return (
    <Tooltip title={t('languageSwitch:tooltipTitle')}>
      <Button
        sx={{ minWidth: '.2rem' }}
        onClick={handleLanguageSwitch}
      >
        {lang}
      </Button>
    </Tooltip>
  );
};

export default LanguageSwitch;
